import * as i0 from '@angular/core';
import { Component, NgModule, Directive, Optional, ViewChild } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@ngx-formly/core';
import { FieldWrapper, FormlyModule, FieldType as FieldType$1 } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
function FormlyWrapperFormField_ng_template_0_label_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵtext(1, "*");
    i0.ɵɵelementEnd();
  }
}
function FormlyWrapperFormField_ng_template_0_label_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 7);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, FormlyWrapperFormField_ng_template_0_label_0_span_2_Template, 2, 0, "span", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("for", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.props.required && ctx_r0.props.hideRequiredMarker !== true);
  }
}
function FormlyWrapperFormField_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, FormlyWrapperFormField_ng_template_0_label_0_Template, 3, 3, "label", 6);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.props.label && ctx_r0.props.hideLabel !== true);
  }
}
function FormlyWrapperFormField_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementContainer(1, 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const labelTemplate_r2 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", labelTemplate_r2);
  }
}
function FormlyWrapperFormField_ng_template_4_Template(rf, ctx) {}
function FormlyWrapperFormField_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementContainer(1, 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const labelTemplate_r2 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", labelTemplate_r2);
  }
}
function FormlyWrapperFormField_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵelement(1, "formly-validation-message", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("display", "block");
    i0.ɵɵadvance();
    i0.ɵɵpropertyInterpolate1("id", "", ctx_r0.id, "-formly-validation-error");
    i0.ɵɵproperty("field", ctx_r0.field);
  }
}
function FormlyWrapperFormField_small_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "small", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.props.description);
  }
}
const _c0 = ["fieldTypeTemplate"];
class FormlyWrapperFormField extends FieldWrapper {}
FormlyWrapperFormField.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyWrapperFormField_BaseFactory;
  return function FormlyWrapperFormField_Factory(__ngFactoryType__) {
    return (ɵFormlyWrapperFormField_BaseFactory || (ɵFormlyWrapperFormField_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyWrapperFormField)))(__ngFactoryType__ || FormlyWrapperFormField);
  };
})();
FormlyWrapperFormField.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyWrapperFormField,
  selectors: [["formly-wrapper-form-field"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 9,
  vars: 8,
  consts: [["labelTemplate", ""], ["fieldComponent", ""], [1, "mb-3"], [4, "ngIf"], ["class", "invalid-feedback", 3, "display", 4, "ngIf"], ["class", "form-text text-muted", 4, "ngIf"], ["class", "form-label", 4, "ngIf"], [1, "form-label"], ["aria-hidden", "true", 4, "ngIf"], ["aria-hidden", "true"], [3, "ngTemplateOutlet"], [1, "invalid-feedback"], ["role", "alert", 3, "id", "field"], [1, "form-text", "text-muted"]],
  template: function FormlyWrapperFormField_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyWrapperFormField_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵtemplate(3, FormlyWrapperFormField_ng_container_3_Template, 2, 1, "ng-container", 3)(4, FormlyWrapperFormField_ng_template_4_Template, 0, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(6, FormlyWrapperFormField_ng_container_6_Template, 2, 1, "ng-container", 3)(7, FormlyWrapperFormField_div_7_Template, 2, 5, "div", 4)(8, FormlyWrapperFormField_small_8_Template, 2, 1, "small", 5);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵclassProp("form-floating", ctx.props.labelPosition === "floating")("has-error", ctx.showError);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.labelPosition !== "floating");
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.props.labelPosition === "floating");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.showError);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.description);
    }
  },
  dependencies: [i1.ɵFormlyValidationMessage, i2.NgIf, i2.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyWrapperFormField, [{
    type: Component,
    args: [{
      selector: 'formly-wrapper-form-field',
      template: `
    <ng-template #labelTemplate>
      <label *ngIf="props.label && props.hideLabel !== true" [attr.for]="id" class="form-label">
        {{ props.label }}
        <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
      </label>
    </ng-template>

    <div class="mb-3" [class.form-floating]="props.labelPosition === 'floating'" [class.has-error]="showError">
      <ng-container *ngIf="props.labelPosition !== 'floating'">
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </ng-container>

      <ng-template #fieldComponent></ng-template>

      <ng-container *ngIf="props.labelPosition === 'floating'">
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </ng-container>

      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message
          id="{{ id }}-formly-validation-error"
          [field]="field"
          role="alert"
        ></formly-validation-message>
      </div>

      <small *ngIf="props.description" class="form-text text-muted">{{ props.description }}</small>
    </div>
  `
    }]
  }], null, null);
})();
class FormlyBootstrapFormFieldModule {}
FormlyBootstrapFormFieldModule.ɵfac = function FormlyBootstrapFormFieldModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyBootstrapFormFieldModule)();
};
FormlyBootstrapFormFieldModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapFormFieldModule
});
FormlyBootstrapFormFieldModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyModule.forChild({
    wrappers: [{
      name: 'form-field',
      component: FormlyWrapperFormField
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapFormFieldModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyWrapperFormField],
      imports: [CommonModule, ReactiveFormsModule, FormlyModule.forChild({
        wrappers: [{
          name: 'form-field',
          component: FormlyWrapperFormField
        }]
      })]
    }]
  }], null, null);
})();
class FieldType extends FieldType$1 {
  constructor(hostContainerRef) {
    super();
    this.hostContainerRef = hostContainerRef;
  }
  set content(templateRef) {
    if (templateRef && this.hostContainerRef) {
      this.hostContainerRef.createEmbeddedView(templateRef);
    }
  }
}
FieldType.ɵfac = function FieldType_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FieldType)(i0.ɵɵdirectiveInject(i0.ViewContainerRef, 8));
};
FieldType.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FieldType,
  viewQuery: function FieldType_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FieldType, [{
    type: Directive
  }], function () {
    return [{
      type: i0.ViewContainerRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    content: [{
      type: ViewChild,
      args: ['fieldTypeTemplate', {
        static: true
      }]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FieldType, FormlyBootstrapFormFieldModule };
