import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
const _c0 = (a0, a1) => ({
  "form-check-inline": a0,
  "form-switch": a1
});
function FormlyFieldCheckbox_ng_template_0_label_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1, "*");
    i0.ɵɵelementEnd();
  }
}
function FormlyFieldCheckbox_ng_template_0_label_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 4);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, FormlyFieldCheckbox_ng_template_0_label_2_span_2_Template, 2, 0, "span", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("for", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.props.required && ctx_r0.props.hideRequiredMarker !== true);
  }
}
function FormlyFieldCheckbox_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelement(1, "input", 2);
    i0.ɵɵtemplate(2, FormlyFieldCheckbox_ng_template_0_label_2_Template, 3, 3, "label", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(11, _c0, ctx_r0.props.formCheck === "inline" || ctx_r0.props.formCheck === "inline-switch", ctx_r0.props.formCheck === "switch" || ctx_r0.props.formCheck === "inline-switch"));
    i0.ɵɵadvance();
    i0.ɵɵclassProp("is-invalid", ctx_r0.showError)("position-static", ctx_r0.props.formCheck === "nolabel");
    i0.ɵɵproperty("indeterminate", ctx_r0.props.indeterminate && ctx_r0.formControl.value == null)("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field);
    i0.ɵɵattribute("aria-describedby", ctx_r0.id + "-formly-validation-error")("aria-invalid", ctx_r0.showError);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.props.formCheck !== "nolabel");
  }
}
class FormlyFieldCheckbox extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        indeterminate: true,
        hideLabel: true,
        formCheck: 'default'
      }
    };
  }
}
FormlyFieldCheckbox.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldCheckbox_BaseFactory;
  return function FormlyFieldCheckbox_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldCheckbox_BaseFactory || (ɵFormlyFieldCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldCheckbox)))(__ngFactoryType__ || FormlyFieldCheckbox);
  };
})();
FormlyFieldCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldCheckbox,
  selectors: [["formly-field-checkbox"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], [1, "form-check", 3, "ngClass"], ["type", "checkbox", 1, "form-check-input", 3, "indeterminate", "formControl", "formlyAttributes"], ["class", "form-check-label", 3, "for", 4, "ngIf"], [1, "form-check-label", 3, "for"], ["aria-hidden", "true", 4, "ngIf"], ["aria-hidden", "true"]],
  template: function FormlyFieldCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldCheckbox_ng_template_0_Template, 3, 14, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.NgClass, i2.CheckboxControlValueAccessor, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i1.NgIf],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldCheckbox, [{
    type: Component,
    args: [{
      selector: 'formly-field-checkbox',
      template: `
    <ng-template #fieldTypeTemplate>
      <div
        class="form-check"
        [ngClass]="{
          'form-check-inline': props.formCheck === 'inline' || props.formCheck === 'inline-switch',
          'form-switch': props.formCheck === 'switch' || props.formCheck === 'inline-switch'
        }"
      >
        <input
          type="checkbox"
          [class.is-invalid]="showError"
          class="form-check-input"
          [class.position-static]="props.formCheck === 'nolabel'"
          [indeterminate]="props.indeterminate && formControl.value == null"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [attr.aria-describedby]="id + '-formly-validation-error'"
          [attr.aria-invalid]="showError"
        />
        <label *ngIf="props.formCheck !== 'nolabel'" [for]="id" class="form-check-label">
          {{ props.label }}
          <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
        </label>
      </div>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyBootstrapCheckboxModule {}
FormlyBootstrapCheckboxModule.ɵfac = function FormlyBootstrapCheckboxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyBootstrapCheckboxModule)();
};
FormlyBootstrapCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapCheckboxModule
});
FormlyBootstrapCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field']
    }, {
      name: 'boolean',
      extends: 'checkbox'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldCheckbox],
      imports: [CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'checkbox',
          component: FormlyFieldCheckbox,
          wrappers: ['form-field']
        }, {
          name: 'boolean',
          extends: 'checkbox'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapCheckboxModule, FormlyFieldCheckbox };
